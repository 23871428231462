.fillbracket{
    .createbracket{
        padding-top: 50px;
        @media only screen and (max-width:767px){
            padding-top: 20px;
            padding-left: 0;
            padding-right: 0;
            &.create-bkt-tab1{
                .white-box{
                    .bracket-box-name{
                        text-align: left;
                        .name-inner{
                            margin-bottom: 5px;
                        }
                    }
                    .right-btns{
                        position: relative;
                        text-align: right;
                        margin: -28px 0 0;
                    }
                }
            }
            &.my-groups-wrap, &.public-groups-wrap{
                .my-group-left{
                    .mygroup-logo{
                        margin: 0 10px 10px 0;
                        width: 60px;
                        height: 60px;
                    }
                    .public-logo-wrap{
                        justify-content: flex-start;
                    }
                    .group-left-inner{
                        flex-wrap: nowrap;
                    }
                }
                .white-box{
                    .bracket-box-name{
                        max-width:80%;
                        text-align: left;
                    }
                    .right-btns{
                        text-align: right;
                    }
                }
            }
        }
        .inner-body{
            p{
                margin-bottom: 40px;
            }
            h1{
                text-transform:uppercase;
            }
        }
        
    }
    .nav-tabs{
        @media only screen and (max-width:767px){
            justify-content: center;
        }
        .nav-link{
            max-width: 150px;
            padding-left: 0;
            padding-right: 0;
            @media only screen and (max-width:767px){
                font-size: 13px;
                padding: 14px 2%;
                box-sizing: border-box;
                max-width: 85px;
                height: 60px;
                &:last-child{
                    max-width:115px;
                    padding-top: 22px;
                }                
            }
            &.active{
                max-width: 150px;
                @media only screen and (max-width:767px){
                    max-width: 85px;
                    height: 60px;
                    font-size: 13px;
                    &:last-child{
                        max-width:115px;
                    }
                }
            }
        }
    }
    .startbtn{
        .bluebtn{
            border-radius: 5px;
            padding-top: 8px;
        }
    }    
}
.my-brackets-wrap{
    padding: 0 15px 40px;
    @media only screen and (max-width:767px){
        padding: 0 10px;
    }
    .bracket-head-outer{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 21px;
        align-items: center;
        @media only screen and (max-width:599px){
            flex-wrap: wrap;
        }
    }
    .my-bracket-head{
        font-size: 28px;
        color: #14376f;        
        line-height: 44px;
        letter-spacing: 0.7px;
        margin-right: 20px;
        font-weight: 700;
        @media only screen and (max-width:767px){
            font-size: 24px;
            line-height: 26px;
        }
        @media only screen and (max-width:599px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    .search-box{
        margin-left: auto;
        position: relative;
        width: 300px;  
        .btn {
            padding: 0 0 0 15px !important;
        }
    }
    .btn{
        position: absolute;
        left: 0;
        top: 50%;
        transform:translateY(-50%);
        padding: 0 0 0 0px;
        margin: 0;
        line-height: 0;
        opacity: 0.57;
        pointer-events: none;
        svg{
            fill: #8d8d8d;
        }
    }
    .search-group{
        width: 100%;
        height: 47px;
        font-size: 14px;
        color: #363636;
        padding-left: 38px;
        box-shadow: unset;
        position: relative;
        &:focus{
            border-color: #14376f;
            right: 60px;
            transition: all 0.5s ease-in;
        }
        &::placeholder {
            color: #8d8d8d;
            opacity: 1;
          }
          
        &:-ms-input-placeholder { 
           color: #8d8d8d;
          }
          
        &::-ms-input-placeholder {
           color: #8d8d8d;
          }        
    }
    .cross{
        display: block;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
    }
    .search-group:focus + .btn { left: -60px; opacity: 1;}
    .search-group:focus + .btn svg { fill:#14376f;}
    .search-group:focus ~ .cross{ display: block; opacity: 1;} 
    .bracket-top-tab{
        font-size: 16px;
        color: #0d0d0d;
        font-family: $roboto-Condensed;
        letter-spacing: 1px;
        font-style: italic;
        @media only screen and (max-width:599px){
            font-size: 14px;
        }
        span{
            cursor: pointer;
            background: #f8f9fd;
            padding: 10px 15px 8px;
            border-radius: 50px;
            display: inline-block;
            @media only screen and (max-width:599px){
                padding: 6px 12px;
            }
            &:first-child{
                margin-right: 15px;
            }
            &.active{
                background: #0b2f6c;
                color: #fff;
            }
        }
    }
}
.my-group-left{
    @media only screen and (max-width:990px){
        width: 100%;
    }
    .group-left-inner{
        display: flex;
        @media only screen and (max-width:990px){
            justify-content: flex-start;
        }
        @media only screen and (max-width:767px){
            flex-wrap: wrap;
        }
    }
    .mygroup-logo{
        width: 87px;
        height: 87px;
        overflow: hidden;
        border: 1px solid #dedede;
        border-radius: 100%;
        margin-right: 22px;
        @media only screen and (max-width:767px){
            margin: 0 auto 10px;
        }
        & img {
            height: inherit;
            width: inherit;
        }
    }
    .public-logo-wrap{
        display: flex;
        align-items: center;
        @media only screen and (max-width:767px){
            justify-content: center;
        }
    }
    .public-icon{
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 16px;
        text-transform: capitalize;
        color: #9c9c9c;
        margin-left: 10px;
        font-weight: normal;
        img{
            position: relative;
            top: -3px;
            margin-right: 8px;
        }
    }
    .group-text {
        font-size: 14px;
        letter-spacing: 0;
        text-transform: none;
        font-weight: 500;
        margin-top: 12px;
    }
}
.white-box{
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #dddddd;
    padding: 25px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
    box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
    @media only screen and (max-width:767px){
        padding: 10px 10px 15px;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .white-box-inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width:990px){
            flex-wrap: wrap;
        }
    }
    .bracket-box-name{
        font-family: $roboto-Condensed;
        text-transform: uppercase;
        color: #363636;
        font-weight: 600;
        letter-spacing: 1.25px;
        font-size: 20px;
        max-width: 380px;
        @media only screen and (max-width:990px){
            max-width: 575px;
            width: 100%;
        }
        @media only screen and (max-width:767px){
            max-width: 100%;
            text-align: center;
        }
        .name-inner{
            display: block;
            margin-bottom: 12px;
            text-transform: none;
        }
    }
    .mens-circle, .womens-circle, .admin-style{
        text-transform: uppercase;
        background-color: #f8fafb;
        padding: 5px 15px;
        border-radius: 100px;
        font-size: 14px;
        letter-spacing: 0.88px;
        font-style: italic;
        @media only screen and (max-width:767px){
            font-size: 11px;
        }
    }
    .womens-circle{
        background-color: #e3fde3;
        color: #415240;
    }
    .admin-style{
        background-color: #f7f9fc;
        margin-left: 10px;
        position: relative;
        top: -2px;
    }    
    .right-btns{
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: end;
        text-transform: uppercase;
        font-family: $roboto-Condensed;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 16px;       
        position: relative; 
        @media only screen and (max-width:1200px){
            font-size: 13px;
        }
        @media only screen and (max-width:990px){
            width: 100%;
            margin-top: 15px;
            text-align: right;
        }
        @media only screen and (max-width:767px){
            text-align: center;
            font-size: 12px;
            letter-spacing: 0.1px;
        }
        .copied-text {
            position: absolute;
            left: -42px;
            top: 3px;
            font-weight: 500;
            text-transform: initial;
            letter-spacing: 0.5px;
            color: #04ab04;
            font-style: italic;
            display: none;
            @media only screen and (max-width:990px){
                display: block;
            }
        }
        a{
            height: 46px;
            width: 100px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border-radius: 5px;
            padding-top: 2px;
            @media only screen and (max-width:1200px){
                width: 80px;
            }
            @media only screen and (max-width:767px){
                width: 60px;
                height: 35px;
            }
            &:hover{
                text-decoration: none;
            }
            &:focus{
                text-decoration: none;
            }
            &.viewbtn{
                color: #0b2f6c;
                border: 1px solid #0b2f6c;
                text-decoration: none;
                // margin-right: 5px;
                transition: all 0.3s ease-in;
                &:hover{
                    background-color: #0b2f6c;
                    color: #fff;
                    transition: all 0.3s ease-in;
                }
            }
            &.deletebtn{
                text-decoration: none;
                color: #bf4343;
                border: 1px solid #bf4343;
                transition: all 0.3s ease-in;
                &:hover{
                    background-color: #bf4343;
                    color: #ffffff;
                    transition: all 0.3s ease-in;
                }
            }
            &.member-btn{
                color: #0b2f6c;
                width: 135px;
                border: 1px solid #0b2f6c;
                margin-right: 10px;
                transition: all 0.3s ease-in;
                &:hover{
                    background-color: #0b2f6c;
                    color: #fff;
                    transition: all 0.3s ease-in;
                }
                @media only screen and (max-width:1200px){
                    width: 120px;
                }
                @media only screen and (max-width:767px){
                    margin-right: 5px;
                    width: 97px;
                }
                &.active-btn{
                    background-color: #0b2f6c;
                    color: #fff;
                    border-color: #0b2f6c;
                }
                &.disabled {
                    opacity: 0.2;
                }
            }
        }
    }
    .username-white{
        margin-bottom: 5px;
        span{
            font-weight: 700;
        }
    }
}

.white-box-bottom{
    border-top: 1px solid #f0f0f0;
    margin-top: 25px;
    padding-top: 25px;
    @media only screen and (max-width:767px){
        margin-top: 15px;
        padding-top: 15px;
    }
    .whitebox-leaderboard{
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width:990px){
            flex-wrap: wrap;
        }
        .leader-table-left, .leader-table-right{
            width: 48%;
            border: 1px solid #d2d2d2;
            padding: 15px;
            border-radius: 5px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.01);
            @media only screen and (max-width:990px){
                width: 100%;
            }           
        }
        @media only screen and (max-width:990px){
            .leader-table-left{
                margin-bottom: 20px;
            }
        }
    }
    .point-top-wrap{
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        line-height: 44px;
        letter-spacing: 0.7px;
        margin-bottom: 10px;
        font-weight: 700;
        .my-points{
            color: #0b2f6c;
        }
        .points-num{
            color: #4f974d;
        }
    }
    .leaderboard-table{
        table{
            width: 100%;
            thead th{
                padding: 10px 0;
                font-size: 18px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.41px;
            }
            tbody td{
                border-bottom: 1px solid #f4f4f4;
                padding: 7px 0;
                font-size: 14px;
                font-family: "MaisonNeue";
                &:last-child{
                    font-weight: 700;
                }
            }
            thead th, tbody td{
                &:nth-child(1){
                    width: 85%;
                }
                &:nth-child(2){
                    width: 15%;
                    text-align: center;
                }
            }
        }
        .table-headings{
            background-color: #f3f3f3;
            font-weight: 700;
            font-size: 18px;
            padding: 5px 15px 6px;
            letter-spacing: 0.81px;
            line-height: 30px;
            &.mens-pcup{
                color: #e53738;
            }
            &.mens-ccup{
                color: #51984f;
            }
        }
        .table-style{
            tbody{
                tr:last-child{
                    td{
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }
        }
        &.leaderboard-table-team{
            .table-height{
                height: 645px;
            }
            table{
                thead th, tbody td{
                    width: 10%;
                    &:nth-child(1){
                        width: 15%;
                        text-align: left;
                    }
                    &:nth-child(2){
                        width: 35%;
                        text-align: left;
                        padding: 14px 0;
                    }
                    &:nth-child(3){
                        width: 35%;
                        text-align: left;
                        padding-right: 15px;                
                    }
                }
                thead th{
                    &:nth-child(3){  
                        font-size: 18px                    
                    }
                }
                tbody td{
                    &:nth-child(1){
                        padding-left: 20px;
                        font-weight: 700;
                        font-size: 16px
                    }
                }
            }
            .table-style{
                tbody{                    
                    tr:last-child{
                        td{
                            padding: 7px 0;
                            font-size: 14px;
                            font-weight: normal;
                            &:nth-child(1){
                                padding-left: 20px;
                                font-weight: 700;
                                font-size: 14px;
                                vertical-align: top;
                            }
                            &:nth-child(3){
                                font-weight: normal;
                                font-size: 14px;
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }
        }
        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #14376f;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #154085;
        }
    }
    
    .table-height {
        height: 600px;
        overflow: hidden;
        overflow-y: auto;
    }
}
.memberlist-table-outer{
    padding: 30px 20px 13px;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    @media only screen and (max-width:767px){
        padding: 20px 10px 13px;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #14376f;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #154085;
    }
    .memberlist-top-wrap{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 11px;
        margin-bottom: 14px;
        @media only screen and (max-width:767px){
            flex-wrap: wrap;
        }
        .total-members{
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.41px;
            color: #333333;
            font-weight: 600;
            @media only screen and (max-width:767px){
                font-size: 15px;
            }
        }
        .member-right-links{
            span{                
                font-size: 14px;
                font-family: "Montserrat-Bold";
                position: relative;
                @media only screen and (max-width:767px){
                    font-size: 12px;
                }
                &:first-child{
                    &:after{
                        content: '|';
                        color:#cbcbcb;
                        margin: 0 10px;
                    }
                }
                img{
                    position: relative;
                    top: -2px;
                    margin-right: 5px;
                }
            }
            a{
                color: #14376f;
                &:hover{
                    text-decoration: under;
                }
            }
        }
    }
    .table-member-list{
        padding: 0;
        height: 725px;
        overflow: hidden;
        overflow-y: auto;
        .table-member-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 13px;
            padding-bottom: 13px;
            .table-member-left{
                width:45%;
                @media only screen and (max-width:990px){
                    width:70%;
                }                
            }
            .table-member-right{
                padding-right: 20px;
                width:10%;
                text-align: right;
                .dt-icon{
                    cursor: pointer;
                    width: 22px;
                    @media only screen and (max-width:767px){
                        width:18px;
                        margin-left: 5px;
                    }
                }
                &.table-member-center{
                    width:30%;
                    text-align:left;
                    @media only screen and (max-width:599px){
                        width:20%;
                    }
                }
                @media only screen and (max-width:767px){
                    padding-right: 10px;
                    font-size: 13px;
                }
            }
                .table-member-left{
                    width:45%;
                    @media only screen and (max-width:599px){
                        width:auto;
                    }
                }
                .table-member-right{
                    &.table-member-center.admin{
                        width:47.5%;
                        @media only screen and (max-width:990px){
                            width:47.6%;
                        }
                        @media only screen and (max-width:599px){
                            width:29.6%;
                        }
                    }                    
                }
                @media only screen and (max-width:767px){
                    .list-bkt-name{
                        display: none;
                    }
                }
            // &:first-child{
            //     .table-member-left{
            //         width:45%;
            //         @media only screen and (max-width:599px){
            //             width:auto;
            //         }
            //     }
            //     .table-member-right{
            //         &.table-member-center{
            //             width:47.5%;
            //             @media only screen and (max-width:990px){
            //                 width:36.6%;
            //             }
            //             @media only screen and (max-width:599px){
            //                 width:29.6%;
            //             }
            //         }                    
            //     }
            //     @media only screen and (max-width:767px){
            //         .list-bkt-name{
            //             display: none;
            //         }
            //     }
            // }
        }
    }
}
.user-team-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width:767px){
        flex-wrap: wrap;
        .list-bkt-name{
            display: block;
            width:100%;
            margin-left: 45px;
            position: relative;
            top: -6px;
        }
    }
    
    .usr-team-img{
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
        margin-right: 19px;
        @media only screen and (max-width:767px){
            width:35px;
            height: 35px;
            margin-right:10px;
        }
    }
    img{
        width: 100%;
    }
    span{
        font-size: 14px;
        color: #0d0d0d;
        font-weight: normal;
        @media only screen and (max-width:767px){
            font-size: 13px;
        }
    }
    .admin-style{
        margin-left: 15px;
        @media only screen and (max-width:767px){
            margin-left: 5px;
            top: 0;
            padding: 4px 10px;
        }
    }
}

// public groups
.public-groups-wrap{
    padding-bottom: 0 !important;
    .white-box{
        .right-btns{
            .copy-icon{
                margin-right: 33px;
                cursor: pointer;
                position: relative;
            }
            a{
                &.member-btn{
                    margin-right: 0;
                }
            }
        }
    }
    @media screen and (max-width:990px){
        .my-brackets-wrap{
            .bracket-head-outer{
                flex-wrap: wrap;
                .my-bracket-head{
                    width: 100%;
                    margin-bottom: 10px;                    
                }
            }
        }
    }
    @media screen and (max-width:599px){
        .my-brackets-wrap{
            .bracket-head-outer{
                margin-bottom: 10px;
                .my-bracket-head{
                    margin-bottom: 15px;   
                    order: 1;                 
                }                
            }
            .bracket-top-tab{
                order: 2;
            }
            .search-box{
                order: 3;
                margin-top: 15px;
                width: 100%;
                .search-group{
                    &:focus{
                        right: 0;
                        width: 90%;
                    }
                }
                .search-group:focus + .btn { left: 0;}
            }
        }
    }
}
.pagination-group{
    margin-top: 30px;
    @media only screen and (max-width:767px){
        padding-bottom: 30px;
    }
    ul{
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;  
        font-size: 14px;
        letter-spacing: 0.88px;
        li{
            cursor: pointer;
            width: 36px;
            height: 36px;
            background-color: #dedede;
            color: #8d8d8d;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 8px 0 0;
            transition: all 0.3s ease-in-out;
            a{
                color: #8d8d8d;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    text-decoration: none;
                    color: #fff;
                }
                &:focus{
                    text-decoration: none;
                    outline: none;
                }
                &:focus-visible{
                    outline: none;
                }
            }
            &:hover{
                color: #fff;
                background-color: #14376f;
                transition: all 0.3s ease-in-out;
                a{
                    color: #fff;
                    &:focus{
                        text-decoration: none;
                        outline: none;
                    }
                    &:focus-visible{
                        outline: none;
                    }
                }
            }
            &.active{
                color: #fff;
                background-color: #14376f;
            }
            &.selected{
                color: #fff;
                background-color: #14376f;
            }
            &.previous, &.next{
                width: 61px;
            }
            &.disabled{
                pointer-events: none;
                opacity:0.6;
            }
            &.next{
                margin-right: 0;
            }
            span{
                display: block;
                align-items: center;
            }
        }
    }
}
.pagination-wrap{
    ul{
        li{
            &.next-space, &.prev-space{
                width: auto;   
                a{
                    width: 61px;  
                    border-radius: 0 !important;
                }                          
            }
            &:hover{
                background-color: transparent;
            }
            a{
                background-color: #dedede;
                color:#8d8d8d;
                border: 0;
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    color: #fff;
                    background-color: #14376f;
                }
            }            
            &.active{
                a{
                    &.page-link{
                        color: #fff;
                        background-color: #14376f;
                    }
                }
            }
        }
    }
}
// groups
.groups-wrap-outer{
    max-width: 1180px;
    margin: 0 auto;
    @media screen and (max-width:990px){
        margin: 0 10px;
    }
    .groups-head-outer{
        display: flex;
        justify-content:space-between;
        margin-bottom: 21px;
        align-items: center;
        @media screen and (max-width:990px){
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
    }
    .groups-head{
        font-size: 28px;
        color: #14376f;        
        line-height: 44px;
        letter-spacing: 0.7px;
        margin-right: 20px;
        font-weight: 700;
        @media only screen and (max-width:599px){
            font-size: 24px;
            line-height: 26px;
            margin-right: 0;
        }
    }
    .filter-search-box{
        @media only screen and (max-width:599px){
            margin-top: 10px;
        }
        .filter-search-inner{
            display: flex;
            justify-content: space-between;
            .form-group{
                margin-bottom: 0;
            }
            .groups-filter{
                width: 187px;
                margin-right: 15px;
                position: relative;
                @media screen and (max-width:599px){
                    max-width: 150px;
                }
                span{
                    position: absolute;
                    color: #8d8d8d;
                    font-size: 14px;
                    left: 15px;
                    top:50%;
                    transform: translateY(-50%);
                }
                .form-control{
                    height: 47px;
                    font-size: 14px;
                    color: #363636;
                    padding-left: 75px;
                    box-shadow: unset;
                    &:focus{
                        border-color: #14376f;
                    }
                }
            }
            .search-top-groups{
                position: relative;
                .search-group{
                    height: 47px;
                    padding-left: 38px;
                    color: #8d8d8d;
                    font-size: 14px;
                    font-family: "MaisonNeue";
                    width: 300px;
                    box-shadow: unset;
                    position: relative;
                    @media screen and (max-width:599px){
                        width: 100%;
                    }
                    &:focus{
                        border-color: #14376f;
                    }
                }
                .btn{
                    position: absolute;
                    left: 15px;
                    top:50%;
                    transform: translateY(-50%);
                    padding: 0;
                    margin: 0;
                    line-height: 0;
                    pointer-events: none;
                    svg{
                        fill:#8d8d8d;
                        opacity: 0.57;
                    }
                }
            }
        }
    }
}

.groups-table-outer-wrap{
    background-color: #fff;
    padding: 19px 30px 5px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
    @media screen and (max-width:990px){
        padding: 15px 15px 5px;
        .table-height {
            width: 100%;
            overflow: hidden;
            overflow-x: scroll;
        }
    }    
    .table-style-groups{
        width: 100%;
        @media screen and (max-width:990px){
            width:1050px;
            max-width: unset;
        }
        th, td{
            padding-left: 15px;
            padding-right: 15px;
        }
        thead{
            border-bottom: 1px solid #f0f0f0;
            th{
                font-size: 16px;
                line-height: 17px;
                letter-spacing: 0.36px;
                padding-bottom: 17px;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
            }
        }
        tbody{
            td{
                font-size: 14px;
                color: #0d0d0d;
                letter-spacing: 0;
                padding-top: 20px;
                padding-bottom: 21px;
                border-bottom: 1px solid #f0f0f0;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                    .action-btns{
                        display: flex;
                        justify-content: space-between;
                    }
                    span{
                        cursor: pointer;
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .sort-users{
        position: relative;
        cursor: pointer;
        margin-left: 5px;
        top: -4px;
    }
}
.back-top-groups{
    font-size: 15px;
    color: #14376f;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-transform: uppercase;
}
.white-box{
    &.groups-details{
        .bracket-box-name{
            max-width: 100%;
            width: auto;
        }
    }
}
.modal-style-groups{
    .modal-content{
        min-height: auto !important;
        .modal-small{
            &.modal-body{
                padding: 40px 50px 50px;
                @media screen and (max-width:599px){
                    padding: 30px 20px;
                }
            }
        }
    }
    .delete-grp-text{
        color: #333;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.69px;
        font-size: 18px;
    }
}
.bracket-name-style-inner{
    position: relative;
    font-size: 28px;
    letter-spacing: 0.7px;
    text-align: center;
    line-height: 44px;
    background-color: #14376f;
    color: #fff;
    font-family: $roboto-Condensed;
    font-weight: normal;
    padding: 20px 0;
    text-transform: capitalize;
    @media only screen and (max-width:767px){
        font-size: 20px;
        line-height: 1.5;
        padding: 10px 0;
    }
}
.close-bkt-name{
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    right: 65px;
    cursor: pointer;
    @media only screen and (max-width:767px){
        right: 15px;
    }
    img{
        width: 22px;
        @media only screen and (max-width:767px){
            width: 16px;
        }
    }
}

.create-bkt-top-button {
    position: relative;
    width: 256px;
    margin-left: auto;
    @media only screen and (max-width:767px){
        width: 175px;
    }
    .btn{
        pointer-events: auto;
        line-height: normal;
        max-width: 256px;
        background-color: #14376f;
        font-family: "Roboto Condensed",sans-serif;
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        height: 46px;
        width: 100%;
        font-style: normal;
        line-height: 3.3;
        letter-spacing: 1px;
        text-align: center;
        color: #fff;
        opacity: 1;
        position: absolute;
        top: 0;
        @media only screen and (max-width:767px){
            font-size: 12px;
            padding: 0;
            height: 39px;
        }
    }
}

.profile-banner-box {
  .profilemd {
    background-color: #f9f9f9;
  }
}
.Profile-banner {
  .profile-hero {
    width: 100%;
    height: 264px;
    @media screen and (max-width: 700px) {
      margin-bottom: 50px !important;
    }
    img {
      width: 100%;
    }
  }
}

.profileBox {
  .password_b {
    position: relative;
    .password__show {
      position: absolute;
      cursor: pointer;
      right: 20px;
      @media screen and (max-width: 700px) {
        right: 10px;
      }
      bottom: 10px;
    }
  }
  .form-dob-icon {
    position: relative;
    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: 100%;
    }
    .claendaricon {
      // width: 100%;
      // float: right;
      position: absolute;
      left: 86%;
      bottom: 17%;
      @media screen and (max-width: 1370px) {
        bottom: 30%;
      }
      @media screen and (max-width: 500px) {
        bottom: 38% !important;
      }

      img {
        width: 50%;
        @media screen and (max-width: 1370px) {
          width: 25px;
        }
        @media screen and (max-width: 767px) {
          width: 40% !important;
        }
      }
    }
  }

  .form-dob {
    //max-width:476px;
    width: 100% !important;
  }
  .col-md-3 {
    @media screen and (min-width: 768px) {
      flex: 0 0 30%;
      max-width: 30%;
    }
    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }
  }
  .col-md-8 {
    @media screen and (min-width: 768px) {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }
  }

  .userProfile {
    padding: 50px 180px;
    @media screen and (max-width: 800px) {
      padding: 0 !important;
      margin: 0 -15px;
    }
    @media screen and (max-width: 1060px) {
      padding: 0px !important;
    }
    @media screen and (max-width: 1300px) {
      padding: 50px 50px;
    }

    .Profile-box {
      position: relative;
      top: -100px;

      .row {
        .col-md-3 {
          @media screen and (max-width: 1000px) {
            flex: 0 0 36%;
            max-width: 36%;
          }
          @media screen and (max-width: 767px) {
            flex: 100% !important;
            max-width: 100% !important;
          }
        }
        .col-md-8 {
          @media screen and (max-width: 1000px) {
            flex: 0 0 60.666667%;
            max-width: 60.666667%;
          }
          @media screen and (max-width: 767px) {
            flex: 100% !important;
            max-width: 100% !important;
          }
        }
      }

      @media screen and (max-width: 700px) {
        padding: 0 5px;
      }

      .sighnup-img {
        background: white;
        box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
        height: auto;
        padding-bottom: 10px;

        @media screen and (max-width: 700px) {
          border-radius: 5px;
        }

        .ProfileButton {
          width: 100%;
          .btn-primary {
            color: #fff;
            background-color: transparent;
            border-color: transparent;
            height: 35px;
            font-family: $industry-Medium;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            padding: 0px 15px !important;
            font-style: normal;
            line-height: 2.19;
            letter-spacing: 0.89px;
            color: #14376f;
            text-align: left;

            .btn-icon {
              float: left;
              width: 60px;
              img {
                width: 60%;
              }
            }
            .arrow-icon {
              float: right;
            }
          }
          .btn-profile {
            width: 100%;
          }
          height: 35px;
          font-family: $industry-Medium;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          padding: 0px !important;
          background-color: transparent !important;
          font-style: normal;
          line-height: 2.19;
          letter-spacing: 0.89px;
          color: #14376f;
        }

        hr {
          opacity: 0.34;
          background-color: solid 1px #979797;
        }

        .Pointsbox {
          height: 100px;
          padding: 17px 0px;
          text-align: center;
          border-radius: 5px;
          background-color: #f7f9fc;
          margin-bottom: 19px;
          .text {
            font-family: $mirpuri-book;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #6d6d6d;
          }
          .number {
            font-family: $industry-Medium;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.97;
            letter-spacing: 3px;
            color: #4d964c;
          }
          .red {
            color: #f4511e !important;
          }
          .green {
            color: #4d964c !important;
          }
        }
      }
      .sighnup-form {
        background: white;
        box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
        padding: 32px 50px;
        height: auto;
        @media screen and (max-width: 700px) {
          margin-top: 30px;
          height: auto;
          padding: 32px 20px !important;
          padding-bottom: 0px;
        }
        @media screen and (max-width: 1000px) {
          margin-top: 30px;
          height: auto;
          padding: 32px 25px;
          padding-bottom: 0px;
        }

        form {
          .col {
            padding: 0;
          }
        }

        .nav-link:first-child {
          @media screen and (max-width: 760px) {
            margin-right: 15px !important;
          }
        }

        .nav-tabs {
          border: none;
          @media screen and (max-width: 700px) {
            margin-left: -5px;
            margin-right: -5px;
          }
          .nav-link {
            border: none;
            height: 35px;
            font-family: $mirpuri-book;
            font-size: 18px;
            @media screen and (max-width: 700px) {
              font-size: 16px;
            }
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            margin-right: 20px;
            @media screen and (max-width: 700px) {
              margin-right: 0px;
            }
            line-height: 1.94;
            padding: 0;
            letter-spacing: 1.5px;
            color: #8d8d8d;
          }
          .nav-link.active {
            font-family: "MaisonNeue";
            font-size: 18px;
            @media screen and (max-width: 700px) {
              font-size: 16px;
            }
            font-weight: bold;
            padding: 0;
            font-stretch: normal;
            border-width: 10px;
            border-bottom: 2px solid #14376f;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 1.5px;
            color: #14376f;
          }
          .nav-link1.active {
            font-family: "MaisonNeue";
            font-size: 18px;
            font-weight: bold;
            padding: 0;
            font-stretch: normal;
            border-width: 10px;
            border-bottom: 2px solid #14376f;
            font-style: normal;
            line-height: 1.94;
            letter-spacing: 1.5px;
            color: #14376f;
          }
        }
        .tab-content {
          height: auto;
          padding-left: 0;
          margin-top: 15px;
          @media screen and (max-width: 700px) {
            margin-top: 0;
          }
          .changePass {
            margin-top: 43px;

            .col-md-8 {
              padding: 0 !important;
            }
            .form-group {
              margin-bottom: 12px;
              width: 100%;
            }
            .form-control {
              background-color: white !important;
              color: black;
            }
            .form-control:focus {
              box-shadow: 0 0 0 0.2rem #14376f78 !important;
            }
          }
          .editcol {
            @media screen and (max-width: 700px) {
              padding: 0 !important;
            }
          }
          .editbtn {
            text-align: right;
            position: absolute;
            width: 100%;
            bottom: 20px;
            @media screen and (max-width: 600px) {
              text-align: right;
              position: inherit;
              width: 100%;
              bottom: 5px;
            }
            .edit-btn {
              border: none;
              outline: none;
              background-color: transparent;
              font-family: $industry-Medium;
              font-size: 16px;
              @media screen and (max-width: 700px) {
                padding: 10px 0;
              }

              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.14;
              letter-spacing: 1px;
              text-align: center;
              color: #8d8d8d;
            }
            .edit-btn:not(:disabled):not(.disabled):active:focus {
              box-shadow: none !important;
              color: #14376f;
            }
            .btn-primary:focus,
            .btn-primary.focus {
              color: #14376f;
              box-shadow: none !important;
            }
          }
        }
      }
    }

    .pad-bot {
      margin-bottom: 30px;
      @media screen and (max-width: 700px) {
        margin-bottom: 0px;
      }
    }

    .profilepic {
      text-align: center;
      padding-top: 43px;
      @media screen and (max-width: 700px) {
        margin-bottom: 25px;
        margin-bottom: 25px;
      }
      .upimg {
        img {
          width: 100%;
        }
        margin-left: 29%;
        @media screen and (max-width: 700px) {
          margin-left: 0;
        }
        width: 121px;
        height: 121px;
        // background: url("https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/user-default-picture.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: inherit;
        margin-bottom: 25px;
        @media screen and (max-width: 700px) {
          display: inline-table;
        }
      }
      .uploadbtn-hide {
        display: none !important;
      }
      .uploadimg {
        width: 100%;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
        .uploadbtn {
          width: 170px;
          height: 37px;
          font-family: $industry-Medium;
          font-size: 14px;
          border: none;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 1px;
          text-align: center;
          color: #14376f;
          border-radius: 2px;
          background-color: #f5f5f5;
        }
      }
    }

    .termscond {
      width: 100%;
      text-align: left;
      margin-left: -15px;
      p {
        font-family: $mirpuri-book;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: 0.43px;
        color: #777777;

        a {
          color: #14376f;
        }
      }
    }

    .form-check {
      label {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d2d2d;
        margin-right: 34px;
      }
      .form-check-input {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }

    .form-label {
      font-family: $mirpuri-book;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      margin-bottom: 9px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.5px;
      color: #333333;
      text-align: left;
      width: 100%;
    }
    .mb-3 {
      margin-bottom: 25px !important;
      margin-top: 0;
      @media screen and (max-width: 700px) {
        margin-bottom: 20px !important;
        margin-top: 5px;
      }
    }
    .form-row {
      margin-bottom: 30px;
      .fname {
        padding-right: 10px !important;
        @media screen and (max-width: 700px) {
          margin-bottom: 30px;
          padding-left: 3px !important;
          padding-right: 3px !important;
        }
      }
      .lname {
        padding-left: 10px !important;

        @media screen and (max-width: 700px) {
          padding-left: 3px !important;
          padding-right: 3px !important;
        }
      }
    }
    .form-group {
      margin-bottom: 22px;
      width: 100%;
    }

    .form-control {
      height: 47px;
      border-radius: 2px;
      border: solid 1px #d1d1d1;
      background-color: white;
      font-family: $mirpuri-book;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      background-color: #ececec;
      letter-spacing: normal;
      color: #8d8d8d;
    }
    .frombtn {
      max-width: 152px;
      @media screen and (max-width: 700px) {
        max-width: 100%;
      }
      margin-top: 0;

      width: 100%;
      .bluebtn {
        border-radius: 2px;
        background-color: #14376f;
        font-family: $industry-Medium;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        height: 46px;
        width: 100%;
        font-style: normal;
        line-height: 2.14;
        letter-spacing: 1px;
        text-align: center;
        color: white;
      }
    }
    .bluebtn {
      border-radius: 2px;
      background-color: #14376f;
      font-family: $mirpuri-book;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      height: 46px;
      font-style: normal;
      line-height: 2.14;
      letter-spacing: 1px;
      text-align: center;
      color: white;
    }
    .react-calendar{
      height: auto;
      position: absolute;
      z-index: 2;
    }
  }
}
.profile-leaderbord-icon {
  .leaderbord {
    width: 70% !important;
  }
}

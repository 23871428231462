@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.modal-open .modal {
  z-index: 10000000 !important;
}
.forgotpassword {
  .modal-content {
    min-height: auto !important;
  }
}
.modal {
  .mdalshow {
    display: block;
  }
  .modalhide {
    display: none;
  }
  .modal-dialog {
    right: auto;
    left: 0;
    width: 450px;
    @media screen and (max-width: 700px) {
      width: 335px;
      margin: 0px auto;
    }
    @media screen and (max-width: 400px) {
      margin: 00px auto !important;
    }
    padding-top: 30px;
    padding-bottom: 30px;

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 500px;
      height: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: none;
      border-radius: 5px;
      outline: 0;
      @media screen and (max-width: 300px) {
        width: 80% !important;
      }
      .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 39px 28px 36px 28px;

        .form-group {
          margin-bottom: 21px;
        }
        .password_b {
          position: relative;
          .password__show {
            position: absolute;
            cursor: pointer;
            right: 6px;
            bottom: 15px;
          }
        }
        .buttonload {
          background-color: #14376f;
          border: none;
          color: white;
          padding: 12px 24px;
          font-size: 16px;
        }
        .fa {
          margin-left: -12px;
          margin-right: 8px;
        }

        .err-paragraph {
          font-size: 12px;
          color: red;
          width: fit-content;
        }
        .hideforgotbtn {
          display: none;
        }
        .footer-btn {
          width: 100%;
          margin-bottom: 29px;
          .btn {
            width: 100%;
            background-color: #14376f;
            height: 46px;
            border-radius: 0;
            font-family: $roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.14;
            letter-spacing: 1.2px;
            text-align: center;
           
          }
        }
        .footer-forgot-p {
          p {
            font-family: $mirpuri-book;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            text-align: center;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #14376f;
            margin-bottom: 17px;
            cursor: pointer;
          }
        }
        .footer-register {
          p {
            font-family: $mirpuri-book;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            text-align: center;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #000000;

            a {
              font-family: $mirpuri-book;
              font-size: 14px;
              font-weight: bold;
              cursor: pointer;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: #14376f;
              text-decoration: underline;
            }
          }
        }
        label {
          display: inline-block;
          margin-bottom: 0.5rem;
          font-family: $mirpuri-book;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #777777;
        }
        .form-control {
          display: block;
          width: 100%;
          height: 46px;
          padding: 0.375rem 0.75rem;
          font-size: 14px !important;
          font-family: $mirpuri-book;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2d2d !important;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          background-color: white !important;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
    }
    .modal-header {
      padding: 30px 10px 5px 41px;
      @media screen and (max-width: 700px) {
        padding: 30px 0px 5px 41px;
      }
      @media screen and (max-width: 300px) {
        padding: 30px 0px 5px 10px;
      }
      height: 140px;
      margin-left: -0.02px;
      align-items: center;
      background-color: #0e4294;

      .closebtn {
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        outline: none;
        border: none;
        padding: 10px;
      }

      .h4 {
        font-family: $industry-Medium;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.85px;
        text-align: left;
        color: var(--white);
        p {
          font-family: $mirpuri-book;
          margin-top: 6px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.81;
          letter-spacing: normal;
          color: var(--white);
        }
      }
      .close {
        visibility: collapse;
        position: relative;
        top: -50px;
        right: -5px;
        @media screen and (max-width: 700px) {
          right: 5px;
        }
        color: white;
        span {
          font-size: 40px;
        }
      }
    }
  }
}
.tiemodal {
  background-color: #0000006b;
  z-index: 999999;
}
.tieBreakerModal {
  .closebtn {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;
    .btn-primary {
      width: 20px;
      background-color: white;
      color: grey;
      padding: 0;
      font-size: 22px;
      position: relative;
      margin: 0;
      right: -25px;
      top: -10px;
    }
    button {
      width: 20%;
      border: none;
      img {
        vertical-align: baseline;
        border-style: none;
      }
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: grey;
      background-color: white;
      border-color: none;
      box-shadow: none;
    }
  }
  .modal-dialog {
    right: auto;
    left: 5%;
    width: 450px;
    padding-top: 150px !important;
    padding-bottom: 30px;
    .modal-content {
      max-width: 320px;
      width: 100%;
      min-height: 425px !important;
      height: 100%;
      .modal-header {
        display: none !important;
      }
      .modal-body {
        padding: 0 31px 25px;
        .tiebreak {
          padding-top: 10px;
          .scorebox {
            width: 100%;
            table {
              width: 100%;
              tbody {
                box-shadow: 0 3px 16px -4px rgba(0, 0, 0, 0.12);
              }
              .firstrow {
                background-color: #dafad9;
                border: 4px solid white;
                :nth-child(1) {
                  width: 80%;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                  .teamlogo {
                    font-family: $mirpuri-book;
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #333333;
                    span {
                      vertical-align: middle;
                      padding-left: 5px;
                    }
                  }
                }
                :nth-child(2) {
                  text-align: right;
                  width: 50%;
                  .teamscore {
                    font-family: $mirpuri-book;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: right;
                    color: #333333;
                  }
                }
              }
              .secondrow {
                background-color: rgba(156, 0, 0, 0.15);
                border: 4px solid white;
                :nth-child(1) {
                  width: 80%;
                  img {
                    width: 20px;
                    height: 20px;
                  }
                  .teamlogo {
                    font-family: $mirpuri-book;
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #333333;
                    span {
                      vertical-align: middle;
                      padding-left: 5px;
                    }
                  }
                }
                :nth-child(2) {
                  text-align: right;
                  width: 50%;
                  .teamscore {
                    font-family: $mirpuri-book;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: right;
                    color: #333333;
                  }
                }
              }
            }
          }
          .midcontent {
            text-align: center;
            margin-top: 20px;
            h4 {
              font-family: $industry-Medium;
              font-size: 22px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.69px;
              margin: 5px 0;
              text-align: center;
              color: #14376f;
            }
            p {
              font-family: $mirpuri-book;
              font-size: 18px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.61;
              letter-spacing: normal;
              color: #14376f;
              margin-bottom: 0;
            }
          }
          .footercontent {
            table,
            table thead th,
            table tbody tr {
              border-radius: 5px;
              border: solid 1px #c2c2c2;
              border-collapse: separate;
              text-align: center;
              padding: 7px;
            }
            margin-top: 16px;
            h4 {
              font-family: $industry-Medium;
              font-size: 22px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 1.69px;
              text-align: center;
              color: #14376f;
            }
            .scoreboxes {
              width: 100%;
              text-align: center;
              span {
                width: 30%;
                display: inline-block;
                .midborder {
                  position: relative;
                  top: -65px;
                  font-family: $mirpuri-book;
                  font-size: 22px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 1.69px;
                  text-align: center;
                  color: #14376f;
                }
                p {
                  font-family: $mirpuri-book;
                  font-size: 14px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2.07;
                  letter-spacing: 1.4px;
                  color: #8d8d8d;
                  margin: 0;
                }
                table {
                  max-width: 75.2px;
                  margin: 0;
                  width: 100%;
                  height: 98px;
                  tbody {
                    border-radius: 5px;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.03);
                    border: solid 1px #c2c2c2;
                    background-color: #ffffff;

                    h4 {
                      font-family: $industry-Medium;
                      font-size: 24px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: 1.85px;
                      text-align: center;
                      color: #14376f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-style-groups{
  .modal-dialog{
    width: 100%;
    max-width: 570px;
    .modal-content{
      .modal-header{
        padding: 0;
        height: 70px;
        .close{
          display: none;
        }
        .modal-title{
          text-align: center;
          width: 100%;
          color: white;
          font-family: $industry-bold;
          text-transform: uppercase;
        }
        .closebtn{
          top: 10px;
        }
      }
      .modal-body{
        padding: 21px 50px;
        @media screen and (max-width:599px){
          padding: 20px;
        }
        label{
          color: #333;
        }
        .form-group{
          margin-bottom: 12px;
        }
        .create-group-check{
          .form-check{
            padding-left: 24px;
            #check-field {
                margin-left: -24px !important;
                width: 21px;
            }
          }
        }
        .footer-btn{
          margin-bottom: 0;
          margin-top: 15px;
          .btn{
            width: 120px;
            font-family: $roboto;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 4px;
            border: 1px solid #14376f;
            font-weight: 700;
          }
          .cancel-btn {
            color: #14376f;
            background-color: #fff;
            border-color: #14376f;
            margin-left: 15px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .upload-logo{
    margin-bottom: 17px;
    .logo-box{
      width: 99px;
      height: 99px;
      margin:0 auto;
      border-radius: 100%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .upload-logo-text{
      text-align: center;
      margin-top: 10px;
      font-size: 13px;
      color: #8d8d8d;
      font-family: $mirpuri-book;
      display: block;
      cursor: pointer;
    }
  }
  .error-upload{
    text-align: center;
    margin: 0 auto;
  }
  .textarea-modal{
    height: 80px !important;
  }
  .textarea-modal-send{
    height: 100px !important;
  }
  .transfer-btn, .delete-grp-member{
    width: auto !important;
  }
  .footer-btn-delete-modal{
    display: flex;
    margin-top: 23px !important;
    justify-content: center;
  }

  .optionContainer{
    padding: 0 !important; 
    li{
      list-style: none;
      background:white;
      color: #333333;
      &:hover{        
        background-color: #e9e9e9;
        color: #333333;
      }
    }
  }
}

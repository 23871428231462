.verify-mail-container {
  background-color: #f6f6f6;
  padding: 10% 0 20%;
  text-align: center;
  .verify-msg {
    color: #01be0e;
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: 600;
  }
}
// checkmark
.checkmar-outer {
  text-align: center;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #20ba0e;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #20ba0e;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 40px #20ba0e;
  }
}

/////////////////////////////////
.reset-pass-container {
  .password_b {
    position: relative;
    .password__show {
      position: absolute;
      cursor: pointer;
      right: 20px;
      @media screen and (max-width: 700px) {
        right: 10px;
      }
      bottom: 10px;
    }
  }
  background-color: #f6f6f6;
  .reset-outer-wrap {
    background-color: #fff;
    max-width: 1000px;
    margin: 40px auto;
    border-radius: 4px;
    box-shadow: 0 12px 24px 0 rgba(10, 47, 106, 0.04);
    border: solid 1px #dddddd;
    @include breakpoints(small) {
      margin: 40px 4%;
    }
    .reset-inner-wrap {
      padding: 50px 0;
      max-width: 450px;
      margin: 0 auto;
      @include breakpoints(small) {
        max-width: 90%;
      }
      h1 {
        @include heading1;
        text-align: center;
        margin-bottom: 20px;
      }
      .form-group {
        margin-bottom: 30px;
        width: 100%;
      }
      .form-label {
        font-family: $mirpuri-book;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        margin-bottom: 9px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        color: #333333;
        text-align: left;
        width: 100%;
      }
      .form-control {
        height: 47px;
        border-radius: 2px;
        border: solid 1px #d1d1d1;
        background-color: white;
        font-family: "MaisonNeue";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        background-color: #ffffff;
        letter-spacing: normal;
        color: #181818;
      }
      .frombtn {
        max-width: 200px;
        margin-top: 0;
        width: 100%;
        .bluebtn {
          border-radius: 2px;
          background-color: #14376f;
          font-family: $industry-Medium;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          height: 46px;
          width: 100%;
          font-style: normal;
          line-height: 2.14;
          letter-spacing: 2px;
          text-align: center;
          color: white;
        }
      }
    }
  }
}
